<template>
  <div class="doctor-detail-container">
    <item-scroll ref="itemScroll">
      <div class="doctor-detail-main">
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>姓名</div>
          <div class=" global_text_input doctor-detail-value doctor-detail-name">
            <el-input
                v-model.trim="doctorInfo.name"
                placeholder="请输入医生姓名"
                disabled
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>手机号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                @blur="focusout('phone')"
                v-model.trim="doctorInfo.phone"
                placeholder="请输入手机号"
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>身份证号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                @blur="focusout('id_card_number')"
                v-model.trim="doctorInfo.id_card_number"
                placeholder="请输入身份证号"
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>银行（示例：交通银行）</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                v-model.trim="doctorInfo.bank_name"
                placeholder="请输入银行"
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>省份（示例：湖北）</div>
          <div class="global_text_input global_text_select doctor-detail-value">
              <el-select
                v-model="doctorInfo.bank_province"
                placeholder="请选择开户行所在省份"
              >
                <el-option
                  v-for="(item, index) in provinces"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>城市（示例：武汉）</div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
                v-model="doctorInfo.bank_city"
                placeholder="请选择开户行所在城市"
              >
                <el-option
                  v-for="(item, index) in citys"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>开户银行（xx支行/xx营业部）</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                v-model.trim="doctorInfo.bank_name_point"
                placeholder="请输入开户银行"
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>银行账号</div>
          <div class="global_text_input doctor-detail-value">
            <el-input
                @blur="focusout('bank_number')"
                v-model.trim="doctorInfo.bank_number"
                placeholder="请输入银行账号"
              ></el-input>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>身份证照片</div>
          <div class="doctor-detail-value global_container_center">
            <div class="doctor-detail-image-block" @click="selectFile('id_card_front')">
                <img  :src="doctorInfo.id_card_front"  v-if="doctorInfo.id_card_front && !upload.id_card_front"  alt=""   />
                <div  class="doctor-detail-image-select" v-else>
                  <i v-show="!doctorInfo.id_card_front" class="el-icon-plus"></i>
                  <span>{{ getShowName("id_card_front","上传身份证正面") }}</span>
                </div>
            </div>
          </div>
          <div class="doctor-detail-value global_container_center">
            <div class="doctor-detail-image-block" @click="selectFile('id_card_reverse')">
                <img  :src="doctorInfo.id_card_reverse"  v-if="doctorInfo.id_card_reverse && !upload.id_card_reverse"  alt=""   />
                <div  class="doctor-detail-image-select" v-else>
                  <i v-show="!doctorInfo.id_card_reverse" class="el-icon-plus"></i>
                  <span>{{ getShowName("id_card_reverse","上传身份证反面") }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>（以下信息二选一必填）</div>
          <div class="global_text_input doctor-detail-value">
            <el-radio
              v-model.number="doctorInfo.work_type"
              :label="index"
              v-for="(item, index) in config.workType"
              :key="index"
              @change="changeWorkType"
              >{{ item }}</el-radio
            >
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 0">
          <div class="doctor-detail-key">医生ID卡</div>
          <div class="doctor-detail-value global_container_center">
            <div class="doctor-detail-image-block" @click="selectFile('doctor_work_id_card')">
                <img  :src="doctorInfo.doctor_work_id_card"  v-if="doctorInfo.doctor_work_id_card && !upload.doctor_work_id_card"  alt=""   />
                <div  class="doctor-detail-image-select" v-else>
                  <i v-show="!doctorInfo.doctor_work_id_card" class="el-icon-plus"></i>
                  <span>{{ getShowName("doctor_work_id_card","上传医生ID卡") }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
          <div class="doctor-detail-key">官网全景图</div>
          <div class="doctor-detail-value global_container_center">
            <div class="doctor-detail-image-block" @click="selectFile('professional_panoramic')">
                <img  :src="doctorInfo.professional_panoramic"  v-if="doctorInfo.professional_panoramic && !upload.professional_panoramic"  alt=""   />
                <div  class="doctor-detail-image-select" v-else>
                  <i v-show="!doctorInfo.professional_panoramic" class="el-icon-plus"></i>
                  <span>{{ getShowName("professional_panoramic","上传官网全景图") }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block" v-if="doctorInfo.work_type == 1">
          <div class="doctor-detail-key">官网医生身份信息截图页</div>
          <div class="doctor-detail-value global_container_center">
            <div class="doctor-detail-image-block" @click="selectFile('professional_person')">
                <img  :src="doctorInfo.professional_person"  v-if="doctorInfo.professional_person && !upload.professional_person"  alt=""   />
                <div  class="doctor-detail-image-select" v-else>
                  <i v-show="!doctorInfo.professional_person" class="el-icon-plus"></i>
                  <span>{{ getShowName("professional_person","上传官网医生身份信息截图页") }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="doctor-detail-block">
          <div class="doctor-detail-key"><span>*</span>专家职称（示例：住院/主治/副主任/主任医师）</div>
          <div class="global_text_input global_text_select doctor-detail-value">
            <el-select
                v-model="doctorInfo.job_name"
                placeholder="请选择专家职称"
              >
                <el-option
                  v-for="(item, index) in config.titles"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
          </div>
        </div>
        <div class="doctor-detail-block">
          <el-button type="primary" @click.native="doCommit" round>
            <span>提交信息</span>
          </el-button>
        </div>
        
      </div>
    </item-scroll>
    
    <image-cropper
      @receiveData="receiveData"
      ref="imageCropper"
      :CropWidth="400"
      :CropHeight="300"
      :flexScale="[4,3]"
      :isShowHeadimgCalibration="false"
      v-if="cropperManager.url"
    ></image-cropper>
    <input
      type="file"
      v-show="false"
      v-if="cropperManager.resetFile"
      @change="changeFile"
      ref="selectfiles"
    />
  </div>
</template>
<script>
import ItemScroll from '@/components/unit/ItemScroll'
import ImageCropper from '@/components/unit/ImageCropper'
import weixin from "@/common/weixin";
export default {
  name: "doctor-detail",
  components:{
    ItemScroll,
    ImageCropper
  },
  data() {
    return {
      config: {
        titles:["住院医师","主治医师","副主任医师","主任医师"],// 职称列表
        citys: [],
        workType: ["医生ID卡", "官网医生身份信息截图页"],
      },
      upload:{
        phone:0,
        id_card_number:0,
        bank_number:0,
        id_card_front:0,
        id_card_reverse:0,
        doctor_work_id_card:0,
        professional_person:0,
        professional_panoramic:0,
      },
      cropperManager:{
        key: "",
        resetFile: true,
        url: "",
      },
      doctorInfo:{
        id:"",
        openid:"",
        name:"",
        province:"",
        city:"",
        hospital:"",
        hospital_code:"",
        department:"",
        phone:"",
        id_card_number:"",
        bank_province:"",
        bank_city:"",
        bank_name:"",
        bank_name_point:"",
        bank_number:"",
        id_card_front:"",
        id_card_reverse:"",
        meet_count:"",
        work_type:0,
        doctor_work_id_card:"",
        isspecial:"",
        professional_title:"",
        professional_person:"",
        professional_panoramic:"",
        job_name:"",
      },
      dealWithKey: "",
      curbase64: "",
      resetFile: true,
      changeImg: {},
      backInfo:{},
      mergeFields: {
        province: "",
        city: "",
        bank: "",
        deposit_bank: "",
      },
    };
  },
  mounted(){
    setTimeout(()=>{
      this.changeWorkType()
    },500)
  },
  computed:{
    provinces(){
      return this.config.citys.map(item=>item.name)
    },
    citys(){
      if(!this.doctorInfo.province) return []
      let index =  this.provinces.indexOf(this.doctorInfo.province)
      return this.config.citys[index].sub.map(item=>item.name)
    }
  },
  async created() {
    this.doctorInfo.id = this.$route.query.doctorId;
    this.config.citys = await this.$tools.getCityConfig()
    this.loadData();
  },
  methods: {
    receiveData(data) {
      this.cropperManager.url = "";
      this.doctorInfo[this.cropperManager.key] = data.url
      this.upload[this.cropperManager.key] = 0
      if(["doctor_work_id_card","professional_panoramic","professional_person"].includes(this.cropperManager.key)){
        this.loadJobName(data.url)
      }
    },
    getShowName(key,label){
      return this.doctorInfo[key] ? "已上传" : label
    },
    getKey(key) {
      if (this.config.types[key]) return this.config.types;
      if (this.config.merges.indexOf(key) > -1) return this.mergeFields;
      return this.doctorInfo;
    },
    loadJobName(image){
      let url = this.$tools.getURL(this.$urls.admin.job,{url:image})
      this.$axios.get(url).then(res=>{
        if(res.job_name)  this.doctorInfo.job_name = res.job_name
      })
    },
    loadData() {
      let url = this.$tools.getURL(this.$urls.admin.doctor,{id:this.doctorInfo.id})
      this.$axios.get(url).then((res) => {
        this.doctorInfo = {...this.doctorInfo,...res.data}
        for(let key in this.upload){
          this.upload[key] = this.doctorInfo[key] ? 1 : 0
          this.doctorInfo[key] = this.doctorInfo[key] || ""
          let tag = this.doctorInfo[key].replace(/./g,"*")
          tag = tag.substr(0,tag.length-7)
          this.doctorInfo[key] = `${this.doctorInfo[key].substr(0,3)}${tag}${this.doctorInfo[key].substr(-4)}`
        }
        this.backInfo = {...this.doctorInfo}
      });
    },
    focusout(key){
      if(this.isHide(this.doctorInfo[key])){
        this.doctorInfo[key] = this.backInfo[key]
      }
    },
    changeFile(event) {
      let file = event.target.files[0];
      if (!file) return;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (item) => {
        this.cropperManager.url = item.target.result;
        this.cropperManager.resetFile = false;
        console.log(this.cropperManager.url)
        this.$nextTick(() => {
          this.cropperManager.resetFile = true;
          console.log(this.$refs.imageCropper)
          this.$refs.imageCropper.setData(this.cropperManager.url);
        });
      };
    },
    changeWorkType(){
      this.$refs.itemScroll.initScroll();
    },
    
    selectLocalFile() {
      this.$refs.selectfiles.click();
    },
    selectFile(key) {
      if (this.loading || !this.resetFile) return;
      this.cropperManager.key = key;
      if(this.$tools.isLocal()){
        this.selectLocalFile(key)
      }else{
        this.loading = true;
        weixin.selectFile(1, 999999).then((files) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
          this.cropperManager.url = files[0];
          this.$nextTick(() => {
            this.$refs.imageCropper.setData(this.cropperManager.url);
          });
        })
        .catch((err) => {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
      }
    },
    checkPhone() {
      this.doctorInfo.phone = this.doctorInfo.phone.replace(/\D/g, "").substr(0, 11);
    },
    checkCard() {
      this.doctorInfo.id_card_number = this.doctorInfo.id_card_number.replace(/[^\dxX]/g, "").substr(0, 18);
    },
    checkNumber() {
      this.doctorInfo.bank_number = this.doctorInfo.bank_number.replace(/\D/g, "").substr(0, 30);
    },
    isHide(data){
      return (data || "").includes("*")
    },
    checkData() {
      if(!this.isHide(this.doctorInfo.phone)){
        if (!this.doctorInfo.phone) {
          this.$tips.error({ text: "请输入手机号" });
          return false;
        }
        if (!/^1\d{10}$/g.test(this.doctorInfo.phone)) {
          this.$tips.error({ text: "请输入格式正确的手机号" });
          return false;
        }
      }
      if(!this.isHide(this.doctorInfo.id_card_number)){
        if (!this.doctorInfo.id_card_number) {
          this.$tips.error({ text: "请输入身份证号" });
          return false;
        }
        let reg = /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
        if (!reg.test(this.doctorInfo.id_card_number)) {
          this.$tips.error({ text: "身份证号不合法" });
          return false;
        }
      }
      if (!this.doctorInfo.bank_name) {
        this.$tips.error({ text: "请输入银行" });
        return false;
      }
      if (!this.doctorInfo.bank_province) {
        this.$tips.error({ text: "请选择省份" });
        return false;
      }
      if (!this.doctorInfo.bank_city) {
        this.$tips.error({ text: "请选择城市" });
        return false;
      }
      if (!this.doctorInfo.bank_name_point) {
        this.$tips.error({ text: "请输入开户银行" });
        return false;
      }
      if(!this.isHide(this.doctorInfo.bank_number)){
        if (!this.doctorInfo.bank_number) {
          this.$tips.error({ text: "请输入银行账号" });
          return false;
        }
        if (!/(^\d{16}$)|(^\d{17}$)|(^\d{18}$)|(^\d{19}$)/.test(this.doctorInfo.bank_number)) {
          this.$tips.error({ text: "错误的银行账号" });
          return false;
        }
      }
      
      if(!this.isHide(this.doctorInfo.id_card_front)){
        if (!this.doctorInfo.id_card_front) {
          this.$tips.error({ text: "请上传身份证正面" });
          return false;
        }
      }
      if(!this.isHide(this.doctorInfo.id_card_reverse)){
        if (!this.doctorInfo.id_card_reverse) {
          this.$tips.error({ text: "请上传身份证反面" });
          return false;
        }
      }
      if(this.doctorInfo.work_type == 0){
        if (!this.isHide(this.doctorInfo.doctor_work_id_card) && !this.doctorInfo.doctor_work_id_card) {
          this.$tips.error({ text: "请上传医生ID卡" });
          return false;
        }
      }
      if(this.doctorInfo.work_type == 1){
        if (!this.isHide(this.doctorInfo.professional_panoramic) && !this.doctorInfo.professional_panoramic) {
          this.$tips.error({ text: "请上传官网全景图" });
          return false;
        }
        if (!this.isHide(this.doctorInfo.professional_person) && !this.doctorInfo.professional_person) {
          this.$tips.error({ text: "请上传官网医生身份信息截图页" });
          return false;
        }
      }
      if(!this.doctorInfo.job_name){
        this.$tips.error({ text: "请选择职称" });
      }
      return true;
    },
    doCommit() {
      if (!this.checkData() || this.isloading) return;
      this.isloading = true;
      this.$axios.patch(this.$urls.admin.doctor, this.doctorInfo).then((res) => {
        setTimeout(() => {this.isloading = false;  }, 1000);
        this.$router.back()
      }).catch(err=>{
        setTimeout(() => {this.isloading = false;  }, 1000);
      });
    },
  },
};
</script>

<style>
.doctor-detail-container {
  height: 100%;
  width: 100%;
  position: relative;
  padding: 4vw;
  background: #f5f5f5;
  overflow: hidden;
}
.doctor-detail-main{
  height:auto
}
.doctor-detail-block{
  margin-bottom:4vw;
}
.doctor-detail-block .el-button{
  width:100%;
}
.doctor-detail-key {
  color:#6f6f6f;
}
.doctor-detail-key span{
  color:red;
}
.doctor-detail-key{}
.doctor-detail-value{
  margin-top:2vw;
}
.doctor-detail-name .el-input.is-disabled .el-input__inner{
  background:#ccc;
  color:#000;
}
.doctor-detail-name{
  /* padding:0; */
  background:#ccc;
  border:1px solid #ccc;
}
.doctor-detail-image-block{
  width:80vw;
  height:60vw;
  background: #fff;
  border: 1px solid #9f9f9f;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #9f9f9f;
}
.doctor-detail-image-block img{
  width:80vw;
  height:60vw;
}
.doctor-detail-image-select{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.doctor-detail-image-select i{
  font-size: 8vw;
  line-height: 12vw;
}
.doctor-detail-container .customButton_container{
  color:#fff
}
</style>
