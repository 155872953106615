<template>
  <div class="imageCropper_container global_container_center">
    <div class="imageCropper_main">
        <div class="imageCropper_button_group">
          <customButton  class="imageCropper_button" @click="doScale(1)" >放大</customButton>
          <customButton  class="imageCropper_button" @click="doScale(0)" >缩小</customButton>
          <customButton  class="imageCropper_button" @click="doRotate" >旋转</customButton>
          <customButton  class="imageCropper_button" @click="doCut" >确认</customButton>
        </div>
        <div class="imageCropper_area">
          <div class="imageCropper_headimg_calibration_container" v-if="isShowHeadimgCalibration" @touchstart="touchstart">
            <div class="imageCropper_headimg_calibration">
              <img :src="require('@/assets/images/icon/resume_img.png')" alt=""/>
            </div>
          </div>
          <VueCropper
              ref="cropper"
              :img="data.url"
              :autoCrop="true"
              :fixedBox="true"
              :autoCropWidth="CropWidth"
              :autoCropHeight="CropHeight"
              :canMoveBox="false"
              :canMove="true"
              :full="true"
              :canScale="true"
              :fixedNumber="flexScale"
              outputType="png"
              mode="100% 100%"
          ></VueCropper>
        </div>
    </div>
  </div>
</template>
<script>
import 'vue-cropper/dist/index.css'
import { VueCropper } from "vue-cropper"
import customButton from "@/components/unit/CustomButton"
import uploads from "@/common/uploads"
export default {
  name:"imageCropper",
  components: {
    VueCropper:VueCropper,
    customButton:customButton,
  },
  props:{
    CropWidth:{
      type:Number,
      default:300
    },
    CropHeight:{
      type:Number,
      default:150
    },
    flexScale:{
      type:Array,
      default:[2,1]
    },
    uploadItem:{
      type:Object,
      default:{
        upload_type:"card"
      }
    },
    isShowHeadimgCalibration:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      data:{
        url:""
      }
    }
  },
  methods:{
    moveImg(){
      // this.$refs.cropper
    },
    doRotate() {
      console.log("旋转");
      this.$refs.cropper.rotateRight();
    },
    doScale(status) {
      console.log("缩小放大");
      let scale = status === 0 ? -1 : 1;
      this.$refs.cropper.changeScale(scale);
    },
    doCut() {
      console.log("剪裁");
      this.$refs.cropper.getCropData(async (data) => {
        console.log(data)
        let url = await uploads.uploadBase64(data,"",this.uploadItem);
        this.data.url = url;
        this.$emit("receiveData",this.data)
      });
    },
    async getBase64(url){
      return new Promise((resolve)=>{
        console.log(url,window.location)
        let image = new Image();
        image.src = url;
        image.onload = function(){
          let canvas = document.createElement('canvas')
          canvas.width = image.width;
          canvas.height = image.height;
          let ctx = canvas.getContext("2d")
          ctx.drawImage(image,0,0,canvas.width,canvas.height)
          let dataURL = canvas.toDataURL('image/jpeg')
          resolve(dataURL)
        }
        image.onerror = function(err){
          console.log(JSON.stringify(err))
        }
      })
    },
    async setData(url){
      try{
        this.data.url = await this.getBase64(url)
        console.log(this.data.url)
      }catch(err){
        console.log(JSON.stringify(err))
      }
    },
    touchstart(e){
      this.$refs.cropper.startMove(e)
    },
  }
}
</script>
<style>
* { touch-action: pan-y; }
.imageCropper_container{
  position: fixed;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:5001;
  /* background:red; */
  background:rgba(30,30,30,0.7);
}
.imageCropper_main{
  width:80vw;
}
.imageCropper_area{
  position: relative;
  width:100%;
  height:60vw;
  margin-top:4vw;
}
.imageCropper_button_group{
  display:flex;
  justify-content: space-between;
}
.imageCropper_button{
    margin-right:2vw;
    width:18vw !important;
    min-width:18vw !important;
}
.imageCropper_button:nth-child(4){
    margin-right:0;
}
.imageCropper_headimg_calibration_container{
  width: 100%;
  height: 100%;
  background: rgba(129, 129, 129, 0);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 50001;
  display: flex;
  justify-content: center;
}
.imageCropper_headimg_calibration{
  height: 40vw;
  width: 40vw;
  border-radius:40vw;
}
.imageCropper_headimg_calibration img{
  height: 100%;
  width: 100%;
  border:3px solid #fff;
}
</style>